<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        提交订单
      </template>
    </van-nav-bar>
    <div style="padding-bottom: 22rem;">
      <div class="address">
        <div v-if="isAddress">
          <van-cell center is-link @click="addressManagement">
            <template #icon>
              <van-icon name="location" color="#ff976a" size="3rem" />
            </template>
            <template #title>
              <span class="address-name">王帆</span>18810834233
              <van-tag round type="danger">默认</van-tag>
            </template>
            <template #label>
              <span>地址：河北省廊坊市固安县新昌西街56号（一通教育）</span>
            </template>
            <template #right-icon>
              <van-icon name="arrow" size="3rem" color="#666666" />
            </template>
          </van-cell>
        </div>

        <div v-if="!isAddress">
          <van-cell center is-link @click="addressManagement">
            <template #icon>
              <van-icon name="location" color="#ff976a" size="3rem" />
            </template>
            <template #title>
              <span class="address-name">请填写收货地址</span>
            </template>
            <template #right-icon>
              <van-icon name="arrow" size="3rem" color="#666666" />
            </template>
          </van-cell>
        </div>
      </div>
      <div class="order-title">
        <van-cell center title="订单号：PL1658481840RN182" />
      </div>
      <div class="shopList1" v-for="(item, index) in shopList" :key="index">
        <van-card class="shop-card" :tag="item.goods_tag" :title="item.goods_title" :thumb="item.goods_thumb"
          @click="handleClickCourseDetail">
          <template #tags>
            <div class="tags">
              <van-tag plain round type="danger">7天退款</van-tag>
            </div>
          </template>
          <template #bottom>
            <span class="price">￥{{ item.goods_price }}</span>
          </template>
        </van-card>
      </div>
      <div class="order-price">
        <van-cell center title="">
          <template #extra>
            商品金额：<span class="price">￥980.00</span>
          </template>
        </van-cell>
      </div>
      <div class="order-other">
        <van-cell class="freight" center title="运费">
          <template #extra>
            <span class="price">+￥00.00</span>
          </template>
        </van-cell>
        <!-- 优惠券单元格 -->
        <van-coupon-cell 
          class="discount" 
          :coupons="coupons" 
          :chosen-coupon="chosenCoupon" 
          @click="showList = true" />
        <!-- 优惠券列表 -->
        <van-popup 
          v-model:show="showList" 
          round 
          position="bottom" 
          style="height: 80%; padding-top: 0.5rem;">
          <van-coupon-list 
            :coupons="coupons" 
            :chosen-coupon="chosenCoupon" 
            :disabled-coupons="disabledCoupons"
            @change="onChange" 
            @exchange="onExchange" />
        </van-popup>

      </div>
      <div class="order-course-purchase">
        <van-cell class="course-purchase" title="购课须知">
          <template #label>
            <span
              class="text">课程优惠内容在这里，课程优惠内容在这里课程优惠内容在这里课程优惠内容，在这里课程优惠内容在这里内容在这里程优惠内容在这里内容在这里程优惠内容在这里内容在这里程优惠内容在这里内容在这里</span>
          </template>
        </van-cell>
      </div>
    </div>
    <van-submit-bar :price="cartTotal.checkedGoodsAmount * 100" @submit="onSubmit">
      <template #tip>
        <van-checkbox v-model="checked" checked-color="#ee0a24">我已阅读并同意上述购课须知</van-checkbox>
      </template>
      <template #button>
        <van-button class="submit-cart" color="linear-gradient(to right,#ff6034,#ee0a24)">提交订单</van-button>
      </template>
    </van-submit-bar>
  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import { GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood } from '@/request/api'
export default {
  name: 'Cart',
  metaInfo: {
    title: '提交订单', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // 展示选中的商品的数组
      // 哪一个商品的checked的值为1,就可以把它的id添加到这个数组
      result: [],
      // checkedAll: true,
      isAddress: true,

      // 购物车列表数据
      cartList: [],
      // 商品总信息
      cartTotal: [],
      active: '',
      checked: false,
      showList: false,
      onChange: '',
      onExchange: '',
      chosenCoupon: false,
      shopList: [
        {
          goods_tag: "执业药师",
          goods_title: "2022年中药学专业知识一（精品班）",
          goods_thumb: "http://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        },
        {
          goods_tag: "执业药师",
          goods_title: "2022年中药学综合知识与技能（精品班）",
          goods_thumb: "http://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        }
      ],
      coupons: [
        {
          id: 1,//优惠券id
          discount: 0,//折扣券 折值 整数 为0不显示折
          denominations: 100,//优惠券金额 单位分
          originCondition: 50, //满减规则金额 单位分 为0显示无门槛
          value: 12,//折扣券优惠金额，单位分
          name: '优惠券名称',//优惠券名称
          description: '描述信息',//描述信息
          reason: '',	//不可用原因，优惠券不可用时展示
          startAt: 1489104000,//卡有效开始时间 (时间戳, 单位秒)
          endAt: 1514592000//卡失效日期 (时间戳, 单位秒)
        },
        {
          id: 2,//优惠券id
          discount: 0,//折扣券 折值 整数 为0不显示折
          denominations: 1000,//优惠券金额 单位分
          originCondition: 500, //满减规则金额 单位分 为0显示无门槛
          value: 12,//折扣券优惠金额，单位分
          name: '优惠券名称32',//优惠券名称
          description: '描述信息12312',//描述信息
          reason: '',	//不可用原因，优惠券不可用时展示
          startAt: 1489104000,//卡有效开始时间 (时间戳, 单位秒)
          endAt: 1514592000//卡失效日期 (时间戳, 单位秒)
        },
      ],
      defaultCoupon: 0,
      disabledCoupons: [
        {
          id: 2,//优惠券id
          discount: 0,//折扣券 折值 整数 为0不显示折
          denominations: 1000,//优惠券金额 单位分
          originCondition: 500, //满减规则金额 单位分 为0显示无门槛
          value: 12,//折扣券优惠金额，单位分
          name: '不可用优惠券',//优惠券名称
          description: '描述信息12312',//描述信息
          reason: '',	//不可用原因，优惠券不可用时展示
          startAt: 1489104000,//卡有效开始时间 (时间戳, 单位秒)
          endAt: 1514592000//卡失效日期 (时间戳, 单位秒)
        },
      ],

    }
  },
  created() {

  },

  methods: {
    addressManagement() { this.$router.push('/addressManagement') },
    onSubmit() { },
    handleClickCourseDetail() { },

    onBack() {
      history.back();
    },
    onChange(index) { 
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange() {
      this.coupons.push(coupon);
      console.log(code);
     },
    chosenCoupon() { },
    // disabledCoupons(){},
  },
  components: {
    // Tips
  },
  computed: {

  },
}
</script>
 
<style lang = "less" scoped>
.address {
  padding: 7rem 1rem 1rem 1rem;
}

.address .van-cell {
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
}

.address .van-cell .van-cell__title {
  font-size: 2.2rem;
  line-height: 4rem;
  padding-left: 0.5rem;
}

.address .van-cell .van-cell__title .address-name {
  padding-right: 1rem;
}

.address .van-cell .van-cell__title .van-tag {
  position: relative;
  bottom: 0.2rem;
}

.address .van-cell .van-cell__label {
  line-height: 3rem;
  font-size: 2rem;
}


.shopList1 {
  margin: 0 1rem;
}

.shopList1 .shop-card {
  padding: 1.5rem 1rem !important;
}

.van-submit-bar {
  bottom: 0rem;
  border-top: 0.1rem solid #e7e9ed;
}


.collection-cart {
  margin: 0 2rem;
}

.order-title {
  height: 5rem !important;
  margin: 0 1rem;
  line-height: 4rem;
}

.order-title .van-cell {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.order-title .van-cell .van-cell__title {
  color: #333;
  font-size: 2rem;
  padding: 0 1.5rem;
  font-weight: 600;
}

.order-price {
  height: 4rem !important;
  margin: 0 1rem;
  border-top: 0.1rem solid #e7e9ed;
}

.order-price .van-cell {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 0 1.5rem;
  font-size: 2rem;
}

.order-price .van-cell .price {
  padding: 0rem;
  font-size: 2.4rem;
  height: 3rem;
  line-height: 3rem;
  font-weight: 600;
  color: #ee0a24;
}

.order-other {
  height: 4rem !important;
  margin: 3rem 1rem;
}

.order-other .freight {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0 1.5rem;
  font-size: 2rem;
}

.order-other .discount {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0 1.5rem;
  font-size: 2rem;
}

.order-other .discount .van-cell__title {
  font-size: 2rem;
  line-height: 6rem;
}

.order-other .discount .van-cell__value {
  font-size: 2rem;
  line-height: 6rem;
}

.order-other .discount .van-icon {
  font-size: 2.4rem;
  line-height: 6rem;
}

.order-other .van-cell .price {
  padding: 0rem;
  font-size: 2rem;
  height: 2rem;
  line-height: 3rem;
  color: #ee0a24;
}


.order-course-purchase {
  height: 4rem !important;
  padding: 6rem 1rem;
}

.order-course-purchase .course-purchase {
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 2rem;
}

.order-course-purchase .course-purchase .van-cell__label {
  font-size: 1.8rem;
  line-height: 3rem;
  padding-top: 0.5rem;
}

.order-course-purchase .course-purchase .van-icon {
  font-size: 2.4rem;
}
</style>
